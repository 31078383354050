import React from 'react';

const ModalInput = ({id, onInput, value, label, min, max, questionIndex}) => {
	return (
		<div className="modalInput">
			<label htmlFor={id}>{label}</label>
			<input
				id={id}
				type='number'
				min={min}
				max={max}
				onChange={(e)=>onInput(questionIndex, +e.target.value)}
				value={value}
			/>
		</div>
	)
}

export default ModalInput