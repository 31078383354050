import React from 'react';
import ReactQuill from 'react-quill';

const QuillData = ({quillData, quillSetterHandler}) => {
	return (
		<div className='quillWrapper'>
			<ReactQuill
				value={quillData}
				onChange={quillSetterHandler}
			/>
		</div>
	)
}

export default QuillData;