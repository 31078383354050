import {
	CLEAR_APP_STATE,
	GET_ALL_CASES,
	GET_ALL_DRUGS,
	GET_ALL_USERS,
	GET_DRUGS,
	GET_USER_CASES,
	GET_USER_INFO,
} from '../types';

const handlers = {
	[GET_USER_INFO]: (state, {payload}) => ({...state, userInfo: {...payload}}),
	[GET_DRUGS]: (state, {payload}) => ({...state, drugs: [...payload]}),
	[GET_USER_CASES]: (state, {payload}) => ({...state, userCases: [...payload]}),
	[GET_ALL_CASES]: (state, {payload}) => ({...state, allCases: [...payload]}),
	[GET_ALL_USERS]: (state, {payload}) => ({...state, allUsers: [...payload]}),
	[GET_ALL_DRUGS]: (state, {payload}) => ({...state, allDrugs: [...payload]}),
	[CLEAR_APP_STATE]: () => ({
		userInfo: {
			userTitle: null,
			role: null,
			name: null,
			surname: null,
			email: null,
			telephone: null,
			account: null,
			drug: {
				id: null,
				name: null,
				diseaseType: null,
				mainColor: null,
				secondColor: null,
				arrow: null,
				scrollThumb: null,
			},
		},
		userCases: [],
		allCases: [],
		allDrugs: [],
		allUsers: [],
		drugs: [],
	}),
	DEFAULT: state => state,
}

export const appReducer = (state, action) => {
	const handle = handlers[action.type] || handlers.DEFAULT;
	return handle(state, action)
}