import React, {useContext, useEffect, useState} from 'react';
import DashTitle from '../../shared/DashTitle';
import {AppContext} from '../../../context/app/appContext';
import Select from '../../shared/Select';
import LocalLoader from '../../shared/LocalLoader';
import CaseCard from '../../shared/CaseCard';
import Finder from '../../shared/Finder';
import {validDateStr} from '../../../utils/helpfunc';

const SupervisorDach = () => {
	const {appState: {drugs, allCases}, getAllCases} = useContext(AppContext);
	const [filterStatusValue, setFilterStatusValue] = useState(null);
	const [filterDrugsValue, setFilterDrugsValue] = useState(null);
	const [drawArray, setDrawArray] = useState(null);
	const [finderValue, setFinderValue] = useState('');
	
	const filterStatusParams = [
		'All',
		'Wersja robocza',
		'Do akceptacji',
		'Do ponownej akceptacji',
		'Zaakceptowane do publikacji',
		'Zaakceptowane nie do publikacji',
		'Zamknięte',
		'Uwagi',
	];
	const [filterDrugsParams, setFilterDrugsParams] = useState([]);
	
	useEffect(() => {
		if (filterStatusValue && filterDrugsValue && allCases.length) {
			let tempArray = [];
			allCases.forEach(el => {
				tempArray.push({
					id: el['@id'].split('/')[el['@id'].split('/').length - 1],
					author: `${el.user.name} ${el.user.surname}`,
					title: el.title,
					date: el.date,
					status: el.status,
					drug: el.drug.name,
				})
			})
			
			if (filterDrugsValue !== 'All') {
				tempArray = [...tempArray.filter(el => el.drug === filterDrugsValue)]
			}
			
			if (filterStatusValue !== 'All') {
				tempArray = [...tempArray.filter(el => el.status === filterStatusValue.toLowerCase())]
			}
			
			if (finderValue.trim().length !== 0) {
				tempArray = [...tempArray.filter(element => Object.keys(element).some(key =>
						key === 'date'
							?
							validDateStr(new Date(element[key])).includes(finderValue.toLowerCase())
							:
							element[key].toLowerCase().includes(finderValue.toLowerCase()),
					),
				)]
			}
			
			setDrawArray([...tempArray]);
		}
	}, [filterStatusValue, filterDrugsValue, allCases, finderValue])
	
	useEffect(() => {
		if (filterDrugsParams && filterDrugsParams.length > 0) {
			setFilterDrugsValue(filterDrugsParams[0])
			setFilterStatusValue(filterStatusParams[0])
		}
		//eslint-disable-next-line
	}, [filterDrugsParams])
	
	useEffect(() => {
		const tempSortDrugsParams = [];
		tempSortDrugsParams.push('All')
		drugs.forEach(el => tempSortDrugsParams.push(el.name))
		setFilterDrugsParams([...tempSortDrugsParams])
	}, [drugs])
	
	useEffect(() => getAllCases(), [getAllCases]);
	
	const setStatusFilterHandler = (value) => setFilterStatusValue(value)
	const setDrugsFilterHandler = (value) => setFilterDrugsValue(value)
	const finderValueHandler = (value) => setFinderValue(value);
	
	return (
		<div className="supervisorDash">
			<div className="container">
				<DashTitle mainTitle="PRZYPADKI"/>
				{
					filterStatusValue
					&& filterDrugsValue
						?
						<>
							<div className="supervisorDash__sortingArea">
								<div>
									<Select
										value={filterStatusValue}
										label="Status"
										name="statusFilter"
										params={filterStatusParams}
										sortingValueHandler={setStatusFilterHandler}
										supervisor
									/>
									<Select
										value={filterDrugsValue}
										label="Lek"
										name="drugsFilter"
										params={filterDrugsParams}
										sortingValueHandler={setDrugsFilterHandler}
										supervisor
									/>
								</div>
								<Finder
									value={finderValue}
									finderValueHandler={finderValueHandler}
								/>
							</div>
							<div className="supervisorDash__cardsArea">
								{
									drawArray && drawArray.length
										?
										drawArray.map((el) => <CaseCard key={el.id} caseData={el} drugName={el.drug}/>)
										:
										<p>NO DATA</p>
								}
							</div>
						</>
						:
						<LocalLoader/>
				}
			
			</div>
		</div>
	)
}

export default SupervisorDach