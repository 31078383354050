import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Auth from '../components/pages/auth/Auth';
import Registration from '../components/pages/auth/Registration';
import ResetPassword from '../components/pages/auth/ResetPassword';
import DoctorDash from '../components/pages/doctor/DoctorDash';
import Profile from '../components/shared/Profile';
import MyCases from '../components/pages/doctor/MyCases';
import NewCase from '../components/pages/doctor/NewCase';
import Page1 from '../components/pages/doctor/newCasePages/Page1';
import Page2 from '../components/pages/doctor/newCasePages/Page2';
import Page3 from '../components/pages/doctor/newCasePages/Page3';
import Page4 from '../components/pages/doctor/newCasePages/Page4';
import Page5 from '../components/pages/doctor/newCasePages/Page5';
import SupervisorDach from '../components/pages/supervisor/SupervisorDach';
import AccountantDash from '../components/pages/accountant/AccountantDash';
import ModeratorDash from '../components/pages/moderator/ModeratorDash';
import ConfirmUser from '../components/pages/auth/ConfirmUser';
import AdminDash from "../components/pages/admin/AdminDash";
import UsersMenu from '../components/pages/admin/UsersMenu';
import DrugsMenu from '../components/pages/admin/DrugsMenu';

const protectedRoutes = (isLoggedIn, role) => {
	if (!isLoggedIn) {
		return <Routes>
			<Route path="/" element={<Auth/>}/>
			<Route path="/registration" element={<Registration/>}/>
			<Route path="/reset-password" element={<ResetPassword/>}/>
			<Route path="/verification/:token" element={<ConfirmUser/>}/>
			<Route path="/myCases" element={<MyCases/>}/>
			<Route path="*" element={<Navigate to="/"/>}/>
		</Routes>
	} else {
		switch (role) {
			case 'doctor':
				return <Routes>
					<Route path="/" element={<DoctorDash/>}/>
					<Route path="/profile" element={<Profile/>}/>
					<Route path="/myCases" element={<MyCases/>}/>
					<Route
						path="/newCase/"
						element={<NewCase/>
						}>
						<Route exact path="1" element={<Page1/>}/>
						<Route exact path="2" element={<Page2/>}/>
						<Route exact path="3" element={<Page3/>}/>
						<Route exact path="4" element={<Page4/>}/>
						<Route exact path="5" element={<Page5/>}/>
					</Route>
					<Route path="*" element={<Navigate to="/"/>}/>
				</Routes>
			case 'supervisor':
				return <Routes>
					<Route path="/" element={<SupervisorDach/>}/>
					<Route path="/profile" element={<Profile/>}/>
					<Route path="*" element={<Navigate to="/"/>}/>
				</Routes>
			case 'accountant':
				return <Routes>
					<Route path="/" element={<AccountantDash/>}/>
					<Route path="/profile" element={<Profile/>}/>
					<Route path="*" element={<Navigate to="/"/>}/>
				</Routes>
			case 'moderator':
				return <Routes>
					<Route path="/" element={<ModeratorDash/>}/>
					<Route path="/profile" element={<Profile/>}/>
					<Route path="*" element={<Navigate to="/"/>}/>
				</Routes>
			case 'administrator':
				return <Routes>
					<Route path="/" element={<AdminDash/>}/>
					<Route path="/users" element={<UsersMenu/>}/>
					<Route path="/drugs" element={<DrugsMenu/>}/>
					<Route path="/profile" element={<Profile/>}/>
					<Route path="*" element={<Navigate to="/"/>}/>
				</Routes>
			default:
				return <Routes>
					<Route path="/" element={<Auth/>}/>
					<Route path="/registration" element={<Registration/>}/>
					<Route path="/reset-password" element={<ResetPassword/>}/>
					<Route path="/verification/:token" element={<ConfirmUser/>}/>
					<Route path="*" element={<Navigate to="/"/>}/>
				</Routes>
		}
	}
}

export default protectedRoutes;