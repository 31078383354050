import React, {useContext, useState} from 'react';
import DashTitle from './DashTitle';
import {AppContext} from '../../context/app/appContext';
import {useForm} from '../../hooks/form-hook';
import {VALIDATOR_LENGTH, VALIDATOR_NUMBER, VALIDATOR_REQUIRE} from '../../utils/validators';
import FormInput from './formElements/FormInput';
import Button from './formElements/Button';
import {useNavigate} from 'react-router-dom';
import ChangePassword from './modals/ChangePassword';

const Profile = () => {
	
	const {appState: {userInfo}, changeUserInfo} = useContext(AppContext);
	const [isModalActive, setIsModalActive] = useState(false);
	const [formState, inputHandler] = useForm(
		{
			telephone: {
				value: '',
				isValid: false,
			},
			account: {
				value: '',
				isValid: false,
			},
			userTitle: {
				value: '',
				isValid: false,
			},
		},
		false,
	)
	
	const navigate = useNavigate();
	
	const toggleModalHandler = () => setIsModalActive(prev => !prev);
	
	const submitFormHandler = (e) => {
		e.preventDefault()
		changeUserInfo(formState.inputs.userTitle.value, formState.inputs.telephone.value, formState.inputs.account.value);
	}
	
	const goToPreviousPath = () => navigate(-1);
	
	return (
		<>
			<div className="profile container">
				<DashTitle mainTitle="TWOJE DANE"/>
				<form
					className="profile__content"
					onSubmit={submitFormHandler}
				>
					<p className="profile__content-author"><span>Autor:</span>{userInfo.name} {userInfo.surname}</p>
					<FormInput
						id="userTitle"
						element="input"
						type="text"
						label="Afiliacja"
						validators={[VALIDATOR_REQUIRE()]}
						errorText="sprawdź swoją afiliację"
						onInput={inputHandler}
						initialValue={userInfo.userTitle}
						initialValid={false}
					/>
					<FormInput
						id="telephone"
						element="input"
						type="text"
						label="Numer telefonu"
						placeholder="123456789"
						validators={[VALIDATOR_NUMBER(), VALIDATOR_LENGTH(9)]}
						errorText="sprawdź numer telefonu"
						onInput={inputHandler}
						initialValue={userInfo.telephone}
						initialValid={false}
					/>
					<FormInput
						id="account"
						element="input"
						type="text"
						label="Rachunek"
						placeholder="12345678912345678912345678"
						validators={[VALIDATOR_LENGTH(26), VALIDATOR_NUMBER()]}
						errorText="sprawdź rachunek"
						onInput={inputHandler}
						initialValue={userInfo.account}
						initialValid={false}
					/>
					<div className="profile__content-changeButton">
						<Button onClick={toggleModalHandler} type="button">Zmień hasło</Button>
					</div>
					<div className="profile__content-buttonArea">
						<Button onClick={goToPreviousPath}>Powrót</Button>
						<Button type="submit" disabled={!formState.isValid}>ZAPISZ ZMIANY</Button>
					</div>
				</form>
			</div>
			{
				isModalActive && <ChangePassword togglePopup={toggleModalHandler}/>
			}
		</>
	)
}

export default Profile