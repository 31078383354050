import React, {useContext, useEffect} from 'react';
import {AuthContext} from '../../../context/auth/authContext';
import {useParams} from 'react-router-dom';

const ConfirmUser = () => {
	const {confirmUser} = useContext(AuthContext);
	let params = useParams()
	
	useEffect(() => {
		const data = {
			confirmationToken: params.token,
		}
		confirmUser(data)
		// eslint-disable-next-line
	}, [])
	
	return (
		<div className="confirmUser">
			ConfirmUser
		</div>
	)
}

export default ConfirmUser;