import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from '../../../../context/app/appContext';
import {NewCaseContext} from '../../../../context/newCase/newCaseContext';
import NavButton from '../../../shared/NavButton';
import Button from '../../../shared/formElements/Button';
import FormInput from '../../../shared/formElements/FormInput';
import {useForm} from '../../../../hooks/form-hook';
import {VALIDATOR_REQUIRE} from '../../../../utils/validators';

const Page1 = () => {
	const {appState: {userInfo}} = useContext(AppContext);
	const {newCaseState, newCaseState: {pageValidation}, addNewCase, editCase} = useContext(NewCaseContext)
	
	const [caseTitle, caseTitleHandler] = useForm({
		title: {
			value: '',
			isValid: false,
		},
	}, false)
	
	const [drugLinks, setDrugLinks] = useState(null)
	
	useEffect(() => {
		switch (userInfo.drug.name) {
			case 'Macmirror':
				setDrugLinks(
					<>
						<a href="../../../../assets/pdf/Macmiror_Complex%20500_globulki_190124.pdf" download='Macmiror_Complex%20500_globulki_190124.pdf'>Macmiror_Complex
							500_globulki_190124.pdf</a>
						<a href="../../../../assets/pdf/Macmiror_Complex_masc_190124.pdf"
						   download='Macmiror_Complex_masc_190124.pdf'>Macmiror_Complex_masc_190124.pdf</a>
						<a href="../../../../assets/pdf/Macmiror_tabl_190124.pdf"
						   download='Macmiror_tabl_190124.pdf'>Macmiror_tabl_190124.pdf</a>
					</>,
				)
				break;
			case 'Latuda':
				setDrugLinks(
					<a href="../../../../assets/pdf/Latuda_2021.12.02_SPC%20(1).pdf"
					   download='Latuda_2021.12.02_SPC%20(1).pdf'>Latuda_2021.12.02_SPC%20(1).pdf</a>,
				)
				break;
			case 'Trittico':
				setDrugLinks(
					<a  href="../../../../assets/pdf/TritticoCR_20210412.pdf" download='TritticoCR_20210412.pdf'>TritticoCR_20210412.pdf</a>,
				)
				break;
			case 'Vellofent':
				setDrugLinks(
					<>
						<a href="../../../../assets/pdf/Vellofent_210819_SPC.pdf" download='Vellofent_210819_SPC.pdf'>Vellofent_210819_SPC.pdf</a>
					</>,
				)
				break;
			default:
				setDrugLinks(null);
		}
	}, [userInfo.drug.name])
	
	const onSaveHandler = () => {
		if (newCaseState.id) {
			editCase(caseTitle.inputs.title.value, null, null, null, null, null, null);
		} else {
			const data = {
				drug: `/api/drugs/${userInfo.drug.id}`,
				title: caseTitle.inputs.title.value,
			}
			addNewCase(data);
		}
	}
	
	return (
		<div className="nc1">
			<NavButton left hide/>
			<div className="nc1__content">
				<div className="nc1__content-dataArea">
					<h3>Autor:</h3>
					<h2>{userInfo.userTitle} <span>{userInfo.name} {userInfo.surname}</span></h2>
					<FormInput
						id="title"
						element="input"
						type="text"
						label="Tytuł:"
						validators={[VALIDATOR_REQUIRE()]}
						errorText="Wpisz tytuł"
						onInput={caseTitleHandler}
						initialValue={newCaseState.title ? newCaseState.title : ''}
						initialValid={newCaseState.title && newCaseState.title.trim().length > 0}
					/>
					<h5>
						Ważne wskazówki przy tworzeniu opisu przypadku klinicznego.
						<ul className="nc1__content-additionalInfo">
							<li>
								Zastosowanie leku w opisie przypadku powinny być zgodne z zarejestrowaną charakterystyką
								produktu leczniczego ze szczególnym uwzględnieniem wskazania, dawkowania oraz grupy
								wiekowej.
							</li>
							<li>
								Charakterystykę produktu leczniczego {userInfo.drug.name} można znaleźć pod linkiem:
								{drugLinks}
							</li>
							<li>
								Jeżeli potrzebujesz pomocy skontaktuj się z helpdeskiem: <a href="mailto:biuro@softwebo.pl" target='_blank' rel='noreferrer'>biuro@softwebo.pl</a>
							</li>
							<li>
								Działania niepożądane /sytuację szczególną/niespodziewane korzystne działanie leku
								należy zgłosić pod linkiem Zgłoś działanie niepożądane <a href="https://www.angelinipharma.pl/" target='_blank' rel='noreferrer'>www.angelinipharma.pl</a>
							</li>
						</ul>
					</h5>
				</div>
				<div className="nc1__content-buttonArea">
					<Button to="/">ANULUJ</Button>
					<Button
						type="button"
						disabled={caseTitle ? !caseTitle.isValid : true}
						onClick={onSaveHandler}
					>ZAPISZ</Button>
				</div>
			</div>
			<NavButton isActive={pageValidation && pageValidation.p1} to="/newCase/2"/>
		</div>
	)
}

export default Page1;