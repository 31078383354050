//POI TYPES
export const SET_GLOBAL_LOADER = 'SET_GLOBAL_LOADER';
export const UNSET_GLOBAL_LOADER = 'UNSET_GLOBAL_LOADER';
export const SET_MODAL_ERROR = 'SET_MODAL_ERROR';
export const UNSET_MODAL_ERROR = 'UNSET_MODAL_ERROR';

//AUTH TYPES
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

//APP TYPES
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_DRUGS = 'GET_DRUGS';
export const GET_ALL_CASES = 'GET_ALL_CASES';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_DRUGS = 'GET_ALL_DRUGS';
export const GET_USER_CASES = 'GET_USER_CASES';
export const CLEAR_APP_STATE = 'CLEAR_APP_STATE';

//NEW CASE TYPES
export const SET_STARTED_CASE_INFO = 'SET_STARTED_CASE_INFO';
export const GET_CASE_INFO = 'GET_CASE_INFO';
export const CLEAR_NEW_CASE_STATE = 'CLEAR_NEW_CASE_STATE';
export const SET_CASE_ID = 'SET_CASE_ID';
export const UNSET_ISLOADED = 'UNSET_ISLOADED';
export const SET_PAGE_VALIDATION = 'SET_PAGE_VALIDATION';