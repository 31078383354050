import React from 'react';

const Finder = ({finderValueHandler, value}) => {
	return <input
		className="finder"
		type="text"
		placeholder='Search...'
		value={value}
		onChange={(e)=>finderValueHandler(e.target.value)}
	/>
}

export default Finder;