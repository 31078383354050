import React, {useCallback, useEffect, useState} from 'react';
import DashTitle from '../../shared/DashTitle';
import {useForm} from '../../../hooks/form-hook';
import {
    VALIDATOR_LENGTH,
    VALIDATOR_NUMBER,
    VALIDATOR_REQUIRE, VALIDATOR_ZIPCODE,
} from '../../../utils/validators';
import FormInput from '../../shared/formElements/FormInput';
import ImageUploads from '../../shared/formElements/ImageUploads';
import RadioGroup from '../../shared/RadioGroup';
import contractInfoValidator from '../../../utils/contractInfoValidator';
// import contractInfoValidator from '../../../utils/contractInfoValidator';

const ContractForm = ({additionalValueSetter}) => {

    const [secInfo, setSecInfo] = useState(
        {
            isEmployed: '',
            isTaxOfficeRegister: '',
            isPayQuote: '',
            isPensioner: '',
            isCripple: '',
            crippleLevel: '',
            isCareVacation: '',
            isEntrepreneur: '',
            taxLevel: '',
        },
    )

    const [mainInfo, mainInfoHandler] = useForm(
        {
            birthDate: {
                value: '',
                isValid: false,
            },
            birthPlace: {
                value: '',
                isValid: false,
            },
            fatherName: {
                value: '',
                isValid: false,
            },
            motherName: {
                value: '',
                isValid: false,
            },
            citizenship: {
                value: '',
                isValid: false,
            },
            familyName: {
                value: '',
                isValid: false,
            },
            pesel: {
                value: '',
                isValid: false,
            },
            taxOffice: {
                value: '',
                isValid: false,
            },
            account: {
                value: '',
                isValid: false,
            },
            city: {
                value: '',
                isValid: false,
            },
            street: {
                value: '',
                isValid: false,
            },
            houseNumber: {
                value: '',
                isValid: false,
            },
            apartment: {
                value: '',
                isValid: false,
            },
            zipCode: {
                value: '',
                isValid: false,
            },
            post: {
                value: '',
                isValid: false,
            },
            voivodeship: {
                value: '',
                isValid: false,
            },
            district: {
                value: '',
                isValid: false,
            },
            community: {
                value: '',
                isValid: false,
            },
        },
        false,
    )

    const [employerInfo, employerInfoHandler, setEmployerData] = useForm(
        {
            employerName: {
                value: '',
                isValid: false,
            },
            employerAddress: {
                value: '',
                isValid: false,
            },
            employerImage: {
                value: null,
                isValid: true,
            },
        },
        false)

    const [careVacationInfo, careVacationHandler] = useForm(
        {
            startVacation: {
                value: '',
                isValid: false,
            },
            endVacation: {
                value: '',
                isValid: false,
            },
        },
        false,
    )

    const secInfoSetter = useCallback((name, value) => setSecInfo(prev => (
        {
            ...prev,
            [name]: value,
        }
    )), []);

    useEffect(() => {
        secInfo.isEmployed === 'true' && secInfo.isTaxOfficeRegister.length !== 0 && secInfoSetter('isTaxOfficeRegister', '');
        secInfo.isEmployed === 'false' && employerInfo.inputs.employerImage.value !== null && setEmployerData({
            ...employerInfo.inputs,
            employerImage: {value: null, isValid: false},
        }, false);
        secInfo.isCripple === 'false' && secInfo.crippleLevel.length !== 0 && secInfoSetter('crippleLevel', '');
        secInfo.isEntrepreneur === 'false' && secInfo.taxLevel.length !== 0 && secInfoSetter('taxLevel', '');
    }, [secInfo, employerInfo, setEmployerData, secInfoSetter])

    useEffect(() => {
        additionalValueSetter({
            secInfo,
            employerInfo,
            careVacationInfo,
            mainInfo,
            isValid: contractInfoValidator(secInfo, employerInfo, careVacationInfo, mainInfo)
        })
    }, [
        mainInfo,
        secInfo,
        employerInfo,
        careVacationInfo,
        additionalValueSetter,
    ])

    return (
        <div className="contractForm">
            <DashTitle mainTitle="Contract Form"/>
            <FormInput
                id="birthDate"
                element="input"
                type="date"
                label="Data urodzenia"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="sprawdź datę urodzenia"
                onInput={mainInfoHandler}
            />
            <FormInput
                id="birthPlace"
                element="input"
                type="text"
                label="Mejsce urodzenia"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="sprawdź mejsce urodzenia"
                onInput={mainInfoHandler}
            />
            <FormInput
                id="fatherName"
                element="input"
                type="text"
                label="Imię ojca"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="sprawdź imię ojca"
                onInput={mainInfoHandler}
            />
            <FormInput
                id="motherName"
                element="input"
                type="text"
                label="Imię matki"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="sprawdź imię matki"
                onInput={mainInfoHandler}
            />
            <FormInput
                id="citizenship"
                element="input"
                type="text"
                label="Obywatelstwo"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="sprawdź obywatelstwo"
                onInput={mainInfoHandler}
            />
            <FormInput
                id="familyName"
                element="input"
                type="text"
                label="Nazwisko rodowe"
                validators={[]}
                onInput={mainInfoHandler}
            />
            <FormInput
                id="pesel"
                element="input"
                type="text"
                label="Pesel"
                placeholder="12345678901"
                validators={[VALIDATOR_NUMBER(), VALIDATOR_LENGTH(11)]}
                errorText="sprawdź pesel"
                onInput={mainInfoHandler}
            />
            <FormInput
                id="taxOffice"
                element="input"
                type="text"
                label="Urząd skarbowy"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="sprawdź urząd skarbowy"
                onInput={mainInfoHandler}
            />
            <FormInput
                id="account"
                element="input"
                type="text"
                label="Nr konta bankowego"
                placeholder="12345678912345678912345678"
                validators={[VALIDATOR_NUMBER(), VALIDATOR_LENGTH(26)]}
                errorText="sprawdź Nr konta bankowego"
                onInput={mainInfoHandler}
            />
            <FormInput
                id="city"
                element="input"
                type="text"
                label="Miejscowość"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="sprawdź miejscowość"
                onInput={mainInfoHandler}
            />
            <FormInput
                id="street"
                element="input"
                type="text"
                label="Ulica"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="sprawdź nazwę ulicy"
                onInput={mainInfoHandler}
            />
            <FormInput
                id="houseNumber"
                element="input"
                type="text"
                label="Nr domu"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="sprawdź nr domu"
                onInput={mainInfoHandler}
            />
            <FormInput
                id="apartment"
                element="input"
                type="text"
                label="nr lokalu"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="sprawdź nr lokalu"
                onInput={mainInfoHandler}
            />
            <FormInput
                id="zipCode"
                element="input"
                type="text"
                label="Kod pocztowy"
                placeholder="00-000"
                validators={[VALIDATOR_ZIPCODE()]}
                errorText="sprawdź kod pocztowy"
                onInput={mainInfoHandler}
            />
            <FormInput
                id="post"
                element="input"
                type="text"
                label="Poczta"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="sprawdź pocztę"
                onInput={mainInfoHandler}
            />
            <FormInput
                id="voivodeship"
                element="input"
                type="text"
                label="Wojewódstwo"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="sprawdź wojewódstwo"
                onInput={mainInfoHandler}
            />
            <FormInput
                id="district"
                element="input"
                type="text"
                label="Powiat"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="sprawdź powiat"
                onInput={mainInfoHandler}
            />
            <FormInput
                id="community"
                element="input"
                type="text"
                label="Gmina"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="sprawdź gminę"
                onInput={mainInfoHandler}
            />
            <RadioGroup
                name="isEmployed"
                headerTitle="Oświadczam, że"
                valueHandler={secInfoSetter}
                radioValueArray={[
                    {
                        value: 'true',
                        description: 'jestem pracownikiem innego zakladu',
                    },
                    {
                        value: 'false',
                        description: 'nie jestem nigdzie zatrudniony',
                    },
                ]}
            />
            {
                secInfo.isEmployed !== ''
                    ?
                    secInfo.isEmployed === 'true'
                        ?
                        <>
                            <FormInput
                                id="employerName"
                                element="input"
                                type="text"
                                label="Nazwa firmy pracodawcy"
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="sprawdź nazwę firmy"
                                onInput={employerInfoHandler}
                            />
                            <FormInput
                                id="employerAddress"
                                element="input"
                                type="text"
                                label="Adres firmy pracodawcy"
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="sprawdź adres firmy"
                                onInput={employerInfoHandler}
                            />
                            <ImageUploads
                                center
                                id="employerImage"
                                onInput={employerInfoHandler}
                                errorText="należy dołączyć xero documentu - ZUS IMIR za ostatni miesąc pracy"
                            />
                        </>
                        :
                        <RadioGroup
                            name="isTaxOfficeRegister"
                            headerTitle="Oświadczam, że"
                            valueHandler={secInfoSetter}
                            radioValueArray={[
                                {
                                    value: 'true',
                                    description: 'jestem zarejestrowanym w Urzędzie Pracy',
                                },
                                {
                                    value: 'false',
                                    description: 'nie jestem zarejestrowanym w Urzędzie Pracy',
                                },
                            ]}
                        />
                    :
                    null

            }
            <RadioGroup
                name="isPayQuote"
                headerTitle="Oświadczam, że"
                valueHandler={secInfoSetter}
                radioValueArray={[
                    {
                        value: 'true',
                        description: 'osiągam',
                    },
                    {
                        value: 'false',
                        description: 'nie osiągam',
                    },
                ]}
                footerTitle="przychody stanowiące podstawę wymiaru składki na
					ubezpieczenia emerytalnei rentowe w przeliczeniu na okres miesiąca w wysokości nie niższej od
					minimalnego wynagrodzenia zapracę, tj. 3010,00 zł./brutto/ Stawka za 1 godzinę pracy na podstwie
					umowy cywilno-prawnewynosi 19,70 zł brutto z Stawka obowiazuje od 1-01-2022r. do 31-12-2022 r."
            />
            <RadioGroup
                name="isPensioner"
                headerTitle="Oświadczam, że"
                valueHandler={secInfoSetter}
                radioValueArray={[
                    {
                        value: 'true',
                        description: 'jestem emerytem',
                    },
                    {
                        value: 'false',
                        description: 'nie jestem jestem emerytem',
                    },
                ]}
            />
            <RadioGroup
                name="isCripple"
                headerTitle="Oświadczam, że"
                valueHandler={secInfoSetter}
                radioValueArray={[
                    {
                        value: 'true',
                        description: 'jestem recistą',
                    },
                    {
                        value: 'false',
                        description: 'nie jestem recistą',
                    },
                ]}
            />
            {
                secInfo.isCripple === 'true'
                    ?
                    <RadioGroup
                        name="crippleLevel"
                        headerTitle="Oświadczam, że posiadam orzeczenie o stopniu niepełnosprawności:"
                        valueHandler={secInfoSetter}
                        radioValueArray={[
                            {
                                value: 'light',
                                description: 'lekkim',
                            },
                            {
                                value: 'medium',
                                description: 'umiarkowanym',
                            },
                            {
                                value: 'hard',
                                description: 'znacznym',
                            },
                        ]}
                    />
                    :
                    null
            }
            <RadioGroup
                name="isCareVacation"
                headerTitle="Oświadczam, że"
                valueHandler={secInfoSetter}
                radioValueArray={[
                    {
                        value: 'true',
                        description: 'jestem na urlopie wychowawczym',
                    },
                    {
                        value: 'false',
                        description: 'nie jestem na urlopie wychowawczym',
                    },
                ]}
            />
            {
                secInfo.isCareVacation === 'true'
                    ?
                    <>
                        <FormInput
                            id="startVacation"
                            element="input"
                            type="date"
                            label="Urlop od"
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="sprawdź datę urlopu"
                            onInput={careVacationHandler}
                        />
                        <FormInput
                            id="endVacation"
                            element="input"
                            type="date"
                            label="Urlop do"
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="sprawdź datę urlopu"
                            onInput={careVacationHandler}
                        />
                    </>
                    : null
            }
            <RadioGroup
                name="isEntrepreneur"
                headerTitle="Oświadczam, że prowadzę działalność gospodarczą"
                valueHandler={secInfoSetter}
                radioValueArray={[
                    {
                        value: 'true',
                        description: 'tak',
                    },
                    {
                        value: 'false',
                        description: 'nie',
                    },
                ]}
            />
            {
                secInfo.isEntrepreneur === 'true'
                    ?
                    <RadioGroup
                        name="taxLevel"
                        headerTitle="Oświadczam, że prowadzę działalność gospodarczą"
                        valueHandler={secInfoSetter}
                        radioValueArray={[
                            {
                                value: 'min',
                                description: 'na urzewilejowanych stawkach ZUS dla przedsiębiorców',
                            },
                            {
                                value: 'max',
                                description: 'na pełnych stawkach ZUS dla przedsiębiorców',
                            },
                        ]}
                    />
                    :
                    null
            }
        </div>
    )
}

export default ContractForm;