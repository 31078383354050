import React from 'react';
import rightNuvButton from '../../assets/img/rightNuvButton.png';
import leftNuvButton from '../../assets/img/leftNuvButton.png';
import {useNavigate} from 'react-router-dom';

const NavButton = ({left, hide, isActive, to}) => {
	
	const navigate = useNavigate();
	
	const onClickHandler = () => isActive && navigate(to)
	
	return (
		<div className="navButtonWrapper">
			{!hide &&
			<img
				className={`navButton ${isActive ? '' : 'navButton__disable'}`}
				src={left ? leftNuvButton : rightNuvButton} alt="navigation button"
				onClick={onClickHandler}
			/>
			}
		</div>
	)
}

export default NavButton;