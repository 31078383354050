import {LOGIN_USER, LOGOUT_USER} from '../types';

const handlers = {
	[LOGIN_USER]: (state, {payload}) => ({...state, isLoggedIn: true, ...payload}),
	[LOGOUT_USER]: () => ({
		isLoggedIn: false,
		token: null,
		expiration: null,
		userId: null,
	}),
	DEFAULT: state => state,
}

export const authReducer = (state, action) => {
	const handle = handlers[action.type] || handlers.DEFAULT
	return handle(state, action)
}