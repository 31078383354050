import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from '../../../context/app/appContext';
import editButton from '../../../assets/img/editButton.png';
import addButton from '../../../assets/img/addButton.png';
import Button from '../../shared/formElements/Button';
import DrugModal from '../../shared/modals/DrugModal';


const DrugsMenu = () => {
	const {getAllDrugs, appState: {allDrugs}} = useContext(AppContext);
	
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalState, setModalState] = useState(null);
	
	useEffect(() => {
		getAllDrugs()
		//eslint-disable-next-line
	}, [])
	
	const openModalToggle = () => setIsModalOpen(prev => !prev)
	
	const clearModalState = () => setModalState(null);
	
	return (
		<div className="drugMenu container">
			<Button
				onClick={openModalToggle}
			>
				<img
					className="drugMenu__addButton"
					src={addButton}
					alt="add button"
				/>
				<span>Add drug</span>
			</Button>
			<ul className="drugMenu__drugs">
				<li className="drugMenu__drug">
					<div className="drugMenu__drug-id">id</div>
					<div className="drugMenu__drug-name">name</div>
					<div className="drugMenu__drug-uCount">users</div>
					<div className="drugMenu__drug-color">color</div>
					<div className="drugMenu__drug-edit">edit</div>
				</li>
				{
					allDrugs.length &&
					allDrugs.map(el =>
						<li
							className="drugMenu__drug"
							key={el.id}
						>
							<div className="drugMenu__drug-id">{el.id}.</div>
							<div className="drugMenu__drug-name">{el.name}</div>
							<div className="drugMenu__drug-uCount">{el.usersCount}</div>
							<div className="drugMenu__drug-color"><span style={{backgroundColor: el.mainColor}}/></div>
							<div className="drugMenu__drug-edit">
								<img
									src={editButton}
									alt="edit button"
									onClick={() => {
										setModalState({...el})
										openModalToggle()
									}
								}/>
							</div>
						</li>)
					
				}
			</ul>
			{
				isModalOpen
					?
					modalState
						?
						<DrugModal closeModal={openModalToggle} defaultDrugInfo={modalState} clearModalState={clearModalState}/>
						:
						<DrugModal closeModal={openModalToggle}/>
					:
					null
			}
		</div>
	)
}

export default DrugsMenu;