import React, {useContext, useEffect, useState} from 'react';
import NavButton from '../../../shared/NavButton';
import Button from '../../../shared/formElements/Button';
import QuillData from '../../../shared/QuillData';
import {NewCaseContext} from '../../../../context/newCase/newCaseContext';

const Page5 = () => {
	const {newCaseState, newCaseState: {pageValidation}, editCase, caseToApprove} = useContext(NewCaseContext);
	const [isValidPage, setIsValidPage] = useState(false);
	const [quillData, setQuillData] = useState(newCaseState.conclusion);
	
	useEffect(() => {
		quillData && quillData.length > 0 && quillData !== '<p><br></p>' ? setIsValidPage(true) : setIsValidPage(false);
	}, [quillData])
	
	const quillSetterHandler = (value) => setQuillData(value);
	
	const onSaveHandler = () => {
		editCase(null, null, null, null, null, null, quillData)
	}
	
	return (
		<div className="subInfo">
			<NavButton isActive={true} to="/newCase/4" left/>
			<div className="subInfo__content">
				<div className="subInfo__content-dataArea">
					<h3>Dotychczasowe leczenie i podsumowanie</h3>
					<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id integer massa eget
						pulvinar est.
						Ipsum dignissim varius sem etiam tristique tempor, in euismod commodo.</h6>
					<QuillData quillData={quillData} quillSetterHandler={quillSetterHandler}/>
				</div>
				<div className="subInfo__content-buttonArea">
					<Button to="/">ANULUJ</Button>
					<Button
						type="button"
						disabled={!isValidPage}
						onClick={onSaveHandler}
					>ZAPISZ WERSJĘ ROBOCZĄ</Button>
					<Button
						type="button"
						disabled={pageValidation && !pageValidation.isAllValid}
						onClick={caseToApprove}
					>ZAPISZ I WYŚLIJ DO ZATWIERDZENIA</Button>
				</div>
			</div>
			<NavButton hide/>
		</div>
	)
}

export default Page5;