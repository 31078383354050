import React from 'react';

const SharedInput = ({id, label, value, setter, type}) => {
	return (
		<div className="sharedInput">
			<label htmlFor={id}>{label}</label>
			<input
				id={id}
				type={type}
				value={value}
				onChange={setter}
			/>
		</div>
	)
}

export default SharedInput