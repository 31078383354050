import React, {useEffect, useReducer, useState} from 'react';
import {validate} from '../../../utils/validators';
import openEye from '../../../assets/img/openEye.png';
import closeEye from '../../../assets/img/closeEye.png';
import openAuthEye from '../../../assets/img/openAuthEye.png';
import closeAuthEye from '../../../assets/img/closeAuthEye.png';


const inputReducer = (state, action) => {
	switch (action.type) {
		case 'CHANGE':
			return {
				...state,
				value: action.val,
				isValid: validate(action.val, action.validators),
			};
		case 'TOUCH':
			return {
				...state,
				isTouched: true,
			}
		default:
			return state
	}
}

const FormInput = props => {
	const [inputState, dispatch] = useReducer(inputReducer, {
		value: props.initialValue || '',
		isValid: props.initialValid || false,
		isTouched: false,
	})
	
	const {id, onInput, isAuthInput} = props;
	const {value, isValid} = inputState;
	const [customType, setCustomType] = useState('password');
	const [isOpenEye, setIsOpenEye] = useState(false);
	
	const toggleEyeHandler = () => {
		setIsOpenEye(prev => !prev);
	}
	
	useEffect(() => {
		isOpenEye ? setCustomType('text') : setCustomType('password');
	}, [isOpenEye])
	
	useEffect(() => {
		onInput(id, value, isValid)
	}, [id, value, isValid, onInput])
	
	const changeHandler = event => {
		dispatch({
			type: 'CHANGE', val: event.target.value, validators: props.validators,
		})
	}
	
	const touchHandler = () => {
		dispatch({
			type: 'TOUCH',
		})
	}
	
	return (
		<div
			className={`${isAuthInput ? 'authFormControl' : 'formControl'} ${!inputState.isValid && inputState.isTouched && 'invalidFormControl'}`}>
			{!isAuthInput && <label htmlFor={id}>{props.label}</label>}
			{
				props.element === 'input'
					?
					<>
						<input
							id={props.id}
							type={props.type !== 'password' ? props.type : customType}
							style={{paddingLeft: `${props.type === 'password' && '50px'}`}}
							placeholder={props.placeholder}
							onChange={changeHandler}
							value={inputState.value}
							onBlur={touchHandler}
							onInput={touchHandler}
						/>
						{
							props.type === 'password'
							&&
							<img
								src={
									isAuthInput
										?
										isOpenEye ? openAuthEye : closeAuthEye
										:
										isOpenEye ? openEye : closeEye
								}
								onClick={toggleEyeHandler}
								alt="password eye"
							/>
						}
					</>
					:
					<textarea
						id={props.id}
						rows={props.rows || 3}
						onChange={inputState.value}
						onBlur={touchHandler}
					/>
			}
			{!inputState.isValid && inputState.isTouched && <p>{props.errorText}</p>}
		</div>
	)
}

export default FormInput;