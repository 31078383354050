import React from 'react';
import Button from '../formElements/Button';

const UserInfoModal = ({userInfo, closeModal}) => {
	return (
		<div className="userInfo">
			<div className="userInfo__content">
				<h3>User info:</h3>
				<p><span>id: </span>{userInfo.id}</p>
				<p><span>name: </span>{userInfo.name}</p>
				<p><span>surname: </span>{userInfo.surname}</p>
				<p><span>email: </span>{userInfo.email}</p>
				<p><span>title: </span>{userInfo.title}</p>
				<p><span>account: </span>{userInfo.account}</p>
				<p><span>drug: </span>{userInfo.drug}</p>
				<p><span>role: </span>{userInfo.role}</p>
				<p><span>pwz: </span>{userInfo.pwz}</p>
				<p><span>telephone: </span>{userInfo.telephone}</p>
				<div className="userInfo__content-buttonArea">
					<Button onClick={closeModal} type="button">Powrót</Button>
				</div>
			</div>
		</div>
	)
}

export default UserInfoModal;