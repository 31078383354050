import React, {useCallback, useContext, useEffect, useReducer} from 'react';

import {newCaseReducer} from './newCaseReducer';
import {NewCaseContext} from './newCaseContext';
import {PoiContext} from '../poi/poiContext';

import {
	CLEAR_NEW_CASE_STATE,
	GET_CASE_INFO,
	SET_CASE_ID, SET_PAGE_VALIDATION,
	SET_STARTED_CASE_INFO,
} from '../types';

import axios from '../../axios/axios';
import {AuthContext} from '../auth/authContext';

export const NewCaseState = ({children}) => {
	const {setModalError, setGlobalLoader, unsetGlobalLoader} = useContext(PoiContext);
	const {authState: {token}} = useContext(AuthContext);
	
	const initialState = {
		id: null,
		isLoaded: false,
		title: null,
		sex: null,
		age: null,
		description: null,
		beforeTreatment: {
			description: null,
			tests: null,
		},
		afterTreatment: {
			description: null,
			tests: null,
		},
		conclusion: null,
		pageValidation: {
			p1: false,
			p2: false,
			p3: false,
			p4: false,
			p5: false,
			isAllValid: false,
		},
	};
	
	const [state, dispatch] = useReducer(newCaseReducer, initialState);
	
	useEffect(() => {
		const p1 = !!state.title;
		const p2 = !!state.sex && !!state.age && !!state.description;
		const p3 = !!state.beforeTreatment.description;
		const p4 = !!state.afterTreatment.description;
		const p5 = !!state.conclusion;
		const payload = {
			p1,
			p2,
			p3,
			p4,
			p5,
			isAllValid: p1 && p2 && p3 && p4 && p5,
		}
		dispatch({type: SET_PAGE_VALIDATION, payload})
		
	}, [
		state.title,
		state.sex,
		state.description,
		state.age,
		state.beforeTreatment.description,
		state.afterTreatment.description,
		state.conclusion,
	])
	
	const responseParser = (res) => {
		const beforeTreatment = {
			description: res.data.caseSteps[0].description,
			tests: [...res.data.caseSteps[0].scales],
		}
		
		const afterTreatment = {
			description: res.data.caseSteps[1].description,
			tests: [...res.data.caseSteps[1].scales],
		}
		
		return {
			title: res.data.title,
			id: res.data.id,
			sex: res.data.sex,
			age: res.data.age,
			description: res.data.description,
			beforeTreatment,
			afterTreatment,
			conclusion: res.data.conclusion,
		}
	}
	
	const addNewCase = async (data) => {
		setGlobalLoader()
		try {
			await axios.post(
				`cases-owned/new`,
				data,
				{
					headers: {Authorization: `Bearer ${token}`},
				},
			).then(res => {
				res.status === 201 && getCaseInfo(res.data.id);
				unsetGlobalLoader();
				let defRes = {
					data: {
						code: 201,
						message: 'dodano nową sprawę',
					},
				}
				setModalError(defRes);
			})
		} catch (e) {
			setModalError(e.response);
			// setModalError(e.response.data.message, e.response.data.code);
		}
	}
	
	const getCaseInfo = async (caseId) => {
		try {
			axios.get(
				`cases-owned/${caseId}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				},
			).then(res => dispatch({type: GET_CASE_INFO, payload: responseParser(res)}))
		} catch (e) {
			setModalError(e.response);
			// setModalError(e.response.data.message, e.response.data.code);
		}
	}
	
	const editCase = async (
		title,
		sex,
		age,
		description,
		beforeTreatment,
		afterTreatment,
		conclusion,
	) => {
		setGlobalLoader();
		const data = {
			title: title === null ? state.title : title,
			sex: sex === null ? state.sex : sex,
			age: age === null ? +state.age : +age,
			description: description === null ? state.description : description,
			caseSteps: [
				{
					type: 'beforeTreatment',
					description: beforeTreatment === null ? state.beforeTreatment.description : beforeTreatment.description,
					scales: beforeTreatment === null ? state.beforeTreatment.tests : beforeTreatment.tests,
				},
				{
					type: 'afterTreatment',
					description: afterTreatment === null ? state.afterTreatment.description : afterTreatment.description,
					scales: afterTreatment === null ? state.afterTreatment.tests : afterTreatment.tests,
				},
			],
			conclusion: conclusion === null ? state.conclusion : conclusion,
		}
		
		try {
			await axios.put(
				`cases-owned/${state.id}`,
				data,
				{
					headers: {Authorization: `Bearer ${token}`},
				},
			).then(res => {
				dispatch({type: GET_CASE_INFO, payload: responseParser(res)})
				unsetGlobalLoader();
				let defRes = {
					data: {
						code: 200,
						message: 'Zmiany zapisane',
					},
				}
				setModalError(defRes);
			});
		} catch (e) {
			unsetGlobalLoader();
			setModalError(e.response);
			// setModalError(e.response.data.message, e.response.data.code);
		}
	}
	
	const setStartedCaseInfo = () => dispatch({type: SET_STARTED_CASE_INFO})
	
	const setCaseId = (id) => dispatch({type: SET_CASE_ID, payload: id})
	
	const clearNewCaseState = useCallback(() => {
		dispatch({type: CLEAR_NEW_CASE_STATE})
	}, [])
	
	const caseToApprove = async () => {
		setGlobalLoader();
		try {
			axios.post(
				`cases-owned/${state.id}/approve`,
				{},
				{
					headers: {Authorization: `Bearer ${token}`},
				},
			).then(res => {
				unsetGlobalLoader();
				let defRes = {
					data: {
						code: 201,
						message: 'sprawa dodana do akceptacji',
					},
				}
				setModalError(defRes);
			})
		} catch (e) {
			unsetGlobalLoader();
			setModalError(e.response);
			// setModalError(e.response.data.message, e.response.data.code);
		}
	}
	
	return (
		<NewCaseContext.Provider value={{
			newCaseState: state,
			getCaseInfo,
			setStartedCaseInfo,
			clearNewCaseState,
			addNewCase,
			setCaseId,
			editCase,
			caseToApprove,
		}}>
			{children}
		</NewCaseContext.Provider>
	)
}