const contractInfoValidator = (secInfo, employerInfo, careVacationInfo, mainInfo) => {
	return !(
		!mainInfo.isValid ||
		secInfo.isEmployed.length === 0 ||
		secInfo.isPayQuote.length === 0 ||
		secInfo.isPensioner.length === 0 ||
		(secInfo.isEmployed === 'true' && !employerInfo.isValid) ||
		(secInfo.isEmployed === 'false' && secInfo.isTaxOfficeRegister.length === 0) ||
		secInfo.isCripple.length === 0 ||
		(secInfo.isCripple === 'true' && secInfo.crippleLevel.length === 0) ||
		secInfo.isCareVacation.length === 0 ||
		(secInfo.isCareVacation === 'true' && !careVacationInfo.isValid) ||
		secInfo.isEntrepreneur.length === 0 ||
		(secInfo.isEntrepreneur === 'true' && secInfo.taxLevel.length === 0)
	)
}

export default contractInfoValidator;