//function returned numbers 0..9 in format -> 00, 01, 02
export const validNumberStr = number => {
	if (number <= 9 && number >= 0) {
		return `0${number}`;
	} else {
		return number;
	}
};

export const validDateStr = (date) => {
	const day = validNumberStr(date.getDate()).toString();
	const month = validNumberStr(date.getMonth()).toString();
	const year = validNumberStr(date.getFullYear()).toString();
	return `${day}.${month}.${year}`
}

//OBJECT ELEMENTS SUM
function obj_values(obj) {
	const results = [];
	for (let property in obj)
		results.push(obj[property]);
	return results;
}

function list_sum(list) {
	return list.reduce(function (previousValue, currentValue, index, array) {
		return previousValue + currentValue;
	});
}

export const object_values_sum = obj => list_sum(obj_values(obj));


//function returned blob file from base64 string
export function DataURIToBlob(dataURI) {
	const splitDataURI = dataURI.split(',');
	const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
	const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
	const ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++)
		ia[i] = byteString.charCodeAt(i);
	return new Blob([ia], {type: mimeString});
}

//sorting objects
export const sortArray = (flag, array, sortParam) => {
	if (array.length !== 0) {
		const tempArray = [...array];
		return typeof array[0][sortParam] === 'number' || typeof array[0][sortParam] === 'bigint' || typeof array[0][sortParam] === 'object' || typeof array[0][sortParam] === 'boolean'
			?
			[...tempArray.sort((a, b) => flag ? a[sortParam] - b[sortParam] : b[sortParam] - a[sortParam])]
			:
			[...tempArray.sort((a, b) => flag ? a[sortParam].localeCompare(b[sortParam]) : b[sortParam].localeCompare(a[sortParam]))];
	} else return array
};

//ifData ? string : null
export const ifData = data => data ? `${data}` : null;


//type DATA to console.log
export const dataToLog = (data) => {
	for (let pair of data.entries()) {
		console.log(pair[0] + ', ' + pair[1]);
	}
}