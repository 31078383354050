import React, {useContext, useEffect, useState} from 'react';
import logo from '../../assets/img/logo.png'
import {NavLink, useLocation} from 'react-router-dom';
import {AuthContext} from '../../context/auth/authContext';
import {AppContext} from '../../context/app/appContext';

const Header = () => {
	const {isLoggedIn, logout} = useContext(AuthContext);
	const {appState: {userInfo: {role}}} = useContext(AppContext)
	const [match, setMatch] = useState()
	
	let location = useLocation()
	
	useEffect(() => location && setMatch(!(location.pathname.split('/').indexOf('newCase') < 0)), [location])
	
	const activeMenuHandler = (isActive, isMatch = false) =>
		isMatch
			?
			`header__menu-link ${match && 'header__menu-activeLink'} ${isActive && 'header__menu-activeLink'}`
			:
			`header__menu-link ${isActive && 'header__menu-activeLink'}`
	
	return (
		<header className="container header">
			<img className="header__logo" src={logo} alt="logo"/>
			<div>
				{
					isLoggedIn && role && (
						<nav className="header__menu">
							<NavLink
								className={({isActive}) => activeMenuHandler(isActive)}
								to="/"
							>
								Przeglądaj przypadki
							</NavLink>
							{
								role === 'doctor' && (
									<NavLink
										className={({isActive}) => activeMenuHandler(isActive, true)}
										to="/newCase/1"
									>
										Dodaj przypadek
									</NavLink>
								)
							}
							{
								role === 'administrator' && (
									<>
										<NavLink
											className={({isActive}) => activeMenuHandler(isActive, true)}
											to="/users"
										>
											Users
										</NavLink>
										<NavLink
											className={({isActive}) => activeMenuHandler(isActive, true)}
											to="/drugs"
										>
											Drugs
										</NavLink>
									</>
								)
							}
							<div className="header__subMenu">
								<p className="header__subMenu-title">Twój profil</p>
								<span className="header__subMenu-menuArrow"/>
								<ul>
									<li>
										<NavLink
											className={({isActive}) => `header__menu-link ${isActive && 'header__menu-activeLink'}`}
											to="/profile"
										>
											Twoje dane
										</NavLink>
									</li>
									{
										role === 'doctor' && (
											<li>
												<NavLink
													className={({isActive}) => `header__menu-link ${isActive && 'header__menu-activeLink'}`}
													to="/myCases"
												>
													Twoje przypadki
												</NavLink>
											</li>
										)
									}
									<li>
										<p className={`header__menu-link`}
										   onClick={logout}>
											Wyloguj śie
										</p>
									</li>
								</ul>
							</div>
						</nav>
					)
				}
			</div>
		</header>
	)
}

export default Header;