import React, {useContext} from 'react';
import {AppContext} from '../../../context/app/appContext';
import Button from '../formElements/Button';

const ChangePayStatusModal = ({id, closeModal}) => {
	const {changePayStatus} = useContext(AppContext);
	
	return (
		<div className="payModal">
			<div className="payModal__content">
				<h3>Change pay status?</h3>
				<div className="payModal__content-buttonArea">
					<Button onClick={closeModal} type="button">Powrót</Button>
					<Button onClick={() => {
						changePayStatus(id)
						closeModal()
					}} type="button">Change</Button>
				</div>
			</div>
		</div>
	)
}

export default ChangePayStatusModal