import React, {useCallback, useContext, useEffect, useReducer} from 'react';

import {PoiContext} from '../poi/poiContext';
import {AuthContext} from './authContext';
import {authReducer} from './authReducer';

import axios from '../../axios/axios';
import {LOGIN_USER, LOGOUT_USER} from '../types';
import {useNavigate} from 'react-router-dom';


let logOutTimer;

export const AuthState = ({children}) => {
	
	const {setModalError, setGlobalLoader, unsetGlobalLoader, errorMessage, errorCode} = useContext(PoiContext);
	
	const initialState = {
		isLoggedIn: false,
		token: null,
		expiration: null,
		userId: null,
	}
	
	const [state, dispatch] = useReducer(authReducer, initialState);
	
	const navigate = useNavigate();
	
	const login = useCallback((token, expirationDate, userId) => {
		const tokenExpirationTime = expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 8); //8h
		dispatch({
			type: LOGIN_USER,
			payload: {
				token,
				expiration: tokenExpirationTime,
				userId,
			},
		})
		localStorage.setItem('userData', JSON.stringify({
			token,
			expiration: tokenExpirationTime.toISOString(),
			userId,
		}))
	}, [])
	
	const logout = useCallback(() => {
		localStorage.removeItem('userData');
		dispatch({type: LOGOUT_USER});
	}, [])
	
	useEffect(() => {
		if (errorMessage === 'Invalid JWT Token' && (errorCode === 401 || errorCode === '401')) {
			logout();
		}
	}, [errorMessage, errorCode, logout])
	
	useEffect(() => {
		if (state.token && state.expiration) {
			const remainingTime = state.expiration.getTime() - new Date().getTime()
			logOutTimer = setTimeout(logout, remainingTime)
		} else {
			clearTimeout(logOutTimer);
		}
	}, [state, logout])
	
	useEffect(() => {
		const storedData = JSON.parse(localStorage.getItem('userData'))
		if (storedData && storedData.token && new Date(storedData.expiration) > new Date() && storedData.userId) {
			login(storedData.token, new Date(storedData.expiration), storedData.userId)
		}
	}, [login])
	
	const authUser = async (data) => {
		try {
			await axios.post(
				'login_check',
				data,
			).then((res) => {
				login(res.data.token, null, res.data.userId)
			})
		} catch (e) {
			setModalError(e.response);
		}
	}
	
	const changePassword = async (oldPassword, newPassword, newConfirmPassword) => {
		setGlobalLoader();
		
		const data = {
			newPassword,
			newConfirmPassword,
			oldPassword,
		}
		
		try {
			await axios.put(
				`users/${state.userId}/change-password`,
				data,
				{
					headers: {Authorization: `Bearer ${state.token}`},
				},
			).then(res => {
				login(res.data.token, null, state.userId);
				unsetGlobalLoader();
				let defRes = {
					data: {
						code: 200,
						message: 'twoje hasło zostało zmienione',
					},
				}
				setModalError(defRes);
			})
		} catch (e) {
			unsetGlobalLoader();
			setModalError(e.response);
		}
	}
	
	const userRegistration = async (data) => {
		setGlobalLoader();
		
		try {
			await axios.post(
				`users`,
				data,
			).then(res => {
				unsetGlobalLoader();
				let defRes = {
					data: {
						code: 200,
						message: 'uzytkownik został dodany',
					},
				}
				setModalError(defRes);
				return navigate('/');
			})
		} catch (e) {
			unsetGlobalLoader();
			setModalError(e.response);
		}
	}
	
	const resetPassword = async (data) => {
		setGlobalLoader()
		try {
			await axios.put(
				'users/reset-password',
				JSON.stringify(data),
			).then(res => {
				unsetGlobalLoader();
				let defRes = {
					data: {
						code: 200,
						message: 'twoje hasło zostało zmienione',
					},
				}
				setModalError(defRes);
			})
		} catch (e) {
			unsetGlobalLoader()
			setModalError(e.response);
		}
	}
	
	const confirmUser = useCallback(async (data) => {
		setGlobalLoader()
		try {
			await axios.post(
				`users/confirm`,
				data,
			).then(res => {
				unsetGlobalLoader();
				let defRes = {
					data: {
						code: 200,
						message: 'Twoje konto zostało aktywowane',
					},
				}
				setModalError(defRes);
				return navigate('/');
			})
		} catch (e) {
			unsetGlobalLoader()
			setModalError(e.response);
			return navigate('/');
		}
	}, [navigate, setGlobalLoader, unsetGlobalLoader, setModalError])
	
	return (
		<AuthContext.Provider value={{
			isLoggedIn: state.isLoggedIn,
			userId: state.userId,
			token: state.token,
			authState: state,
			logout,
			authUser,
			changePassword,
			userRegistration,
			resetPassword,
			confirmUser,
		}}>
			{children}
		</AuthContext.Provider>
	)
}