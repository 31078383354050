import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from '../../../context/auth/authContext';
import {AppContext} from '../../../context/app/appContext';
import DashTitle from '../../shared/DashTitle';
import Button from '../../shared/formElements/Button';
import FormInput from '../../shared/formElements/FormInput';
import {
	VALIDATOR_EMAIL, VALIDATOR_LENGTH, VALIDATOR_NUMBER, VALIDATOR_PASSWORD, VALIDATOR_REQUIRE,
} from '../../../utils/validators';
import {useForm} from '../../../hooks/form-hook';
import InvoiceForm from './InvoiceForm';
import ContractForm from './ContractForm';
import RegistrationSelect from './RegistrationSelect';
import ValidationModal from '../../shared/modals/ValidationModal';

const payFormatData = [{
	name: 'Faktura', value: 'invoice',
}, {
	name: 'Umowa o dzieło', value: 'contract',
}]

const Registration = () => {
	
	const {userRegistration} = useContext(AuthContext);
	const {appState: {drugs}} = useContext(AppContext);
	
	const [formState, inputHandler] = useForm({
		name: {
			value: '',
			isValid: false,
		},
		surname: {
			value: '',
			isValid: false,
		},
		userTitle: {
			value: '',
			isValid: false,
		},
		email: {
			value: '',
			isValid: false,
		},
		pwz: {
			value: '',
			isValid: false,
		},
		telephone: {
			value: '',
			isValid: false,
		},
		password: {
			value: '',
			isValid: false,
		},
		confirmPassword: {
			value: '',
			isValid: false,
		},
	}, false)
	
	const [payFormat, setPayFormat] = useState('');
	const [drugFormat, setDrugFormat] = useState('')
	const [isAllValid, setIsAllValid] = useState(false);
	const [additionalValue, setAdditionalValue] = useState({isValid: false});
	const [hiddenArea, setHiddenArea] = useState(null);
	const [isAcceptPP, setIsAcceptPP] = useState(false);
	const [isValidationModal, setIsValidationModal] = useState(false);
	const [errorArray, setErrorArray] = useState([]);
	
	useEffect(() => {
		
		formState.isValid
		&& formState.password === formState.confirmPassword
		&& drugFormat !== ''
		&& additionalValue.isValid
		&& isAcceptPP
			? setIsAllValid(true) : setIsAllValid(false)
	}, [formState, additionalValue, drugFormat, isAcceptPP])
	
	useEffect(() => {
		switch (payFormat) {
			case 'invoice':
				setHiddenArea(<InvoiceForm additionalValueSetter={additionalValueSetter}/>);
				break
			case 'contract':
				setHiddenArea(<ContractForm additionalValueSetter={additionalValueSetter}/>);
				break
			default:
				setHiddenArea(null);
		}
		setAdditionalValue({isValid: false})
	}, [payFormat]);
	
	const additionalValueSetter = (value) => setAdditionalValue(value);
	
	const openValidationModal = () => setIsValidationModal(true);
	const closeValidationModal = () => setIsValidationModal(false);
	
	const clearErrorArray = () => setErrorArray([])
	
	const submitHandler = (e) => {
		e.preventDefault()
		
		if (isAllValid) {
			let data = new FormData();
			
			const drugId = drugs.filter(el => el.name === drugFormat)[0].id
			
			data.append('name', formState.inputs.name.value);
			data.append('surname', formState.inputs.surname.value);
			data.append('title', formState.inputs.userTitle.value);
			data.append('email', formState.inputs.email.value);
			data.append('pwz', formState.inputs.pwz.value);
			data.append('telephone', formState.inputs.telephone.value);
			data.append('drugs', JSON.stringify([{id: `${drugId}`}]));
			data.append('password', formState.inputs.password.value);
			data.append('confirmPassword', formState.inputs.confirmPassword.value);
			data.append('payType', payFormat);
			
			if (payFormat === 'invoice') {
				data.append('invoice', JSON.stringify({
					name: additionalValue.company.value,
					address: additionalValue.address.value,
					nip: additionalValue.nip.value,
					regon: additionalValue.regon.value,
					account: additionalValue.account.value,
				}))
			}
			
			if (payFormat === 'contract') {
				const {mainInfo: {inputs: mainInfoInputs}, secInfo} = additionalValue
				data.append('contract', JSON.stringify({
					birthDate: mainInfoInputs.birthDate.value,
					birthPlace: mainInfoInputs.birthPlace.value,
					fatherName: mainInfoInputs.fatherName.value,
					motherName: mainInfoInputs.motherName.value,
					citizenship: mainInfoInputs.citizenship.value,
					familyName: mainInfoInputs.familyName.value,
					pesel: mainInfoInputs.pesel.value,
					taxOffice: mainInfoInputs.taxOffice.value,
					account: mainInfoInputs.account.value,
					city: mainInfoInputs.city.value,
					street: mainInfoInputs.street.value,
					houseNumber: mainInfoInputs.houseNumber.value,
					apartment: mainInfoInputs.apartment.value,
					zipCode: mainInfoInputs.zipCode.value,
					post: mainInfoInputs.post.value,
					voivodeship: mainInfoInputs.voivodeship.value,
					district: mainInfoInputs.district.value,
					community: mainInfoInputs.community.value,
					isEmployed: secInfo.isEmployed,
					employerName: additionalValue.employerInfo.inputs.employerName.value,
					employerAddress: additionalValue.employerInfo.inputs.employerAddress.value,
					isTaxOfficeRegister: secInfo.isTaxOfficeRegister ? secInfo.isTaxOfficeRegister : 'false',
					isPayQuote: secInfo.isPayQuote,
					isPensioner: secInfo.isPensioner,
					isCripple: secInfo.isCripple,
					isCrippleLevel: secInfo.crippleLevel,
					isCareVacation: secInfo.isCareVacation,
					startVacation: additionalValue.careVacationInfo.inputs.startVacation.value,
					endVacation: additionalValue.careVacationInfo.inputs.endVacation.value,
					isEntrepreneur: secInfo.isEntrepreneur,
					taxLevel: secInfo.taxLevel,
				}))
				data.append('file', additionalValue.employerInfo.inputs.employerImage.value);
			}
			userRegistration(data)
			
		} else {
			let tempErrorArray = [];
			if (!formState.inputs.name.isValid) {
				tempErrorArray.push('Podaj swoje imię');
			}
			if (!formState.inputs.surname.isValid) {
				tempErrorArray.push('Podaj swoje nazwisko');
			}
			if (!formState.inputs.userTitle.isValid) {
				tempErrorArray.push('Afiliacja');
			}
			if (!formState.inputs.email.isValid) {
				tempErrorArray.push('Nieprawidłowy adres e-mail');
			}
			if (!formState.inputs.pwz.isValid) {
				tempErrorArray.push('Podaj prawidłowy numer PWZ');
			}
			if (!formState.inputs.telephone.isValid) {
				tempErrorArray.push('Błędny numer telefonu');
			}
			if (!formState.inputs.password.isValid) {
				tempErrorArray.push('Wpisz hasło');
			}
			if (!formState.inputs.confirmPassword.isValid) {
				tempErrorArray.push('Powtórz wpisane hasło');
			}
			if (
				formState.inputs.password.value !==
				formState.inputs.confirmPassword.value
			) {
				tempErrorArray.push('password !== confirmPassword');
			}
			if (!drugFormat.length) {
				tempErrorArray.push('Wybierz lek');
			}
			
			if (!payFormat.length) {
				tempErrorArray.push('Wybierz formę platnosci');
			}
			
			if (payFormat === 'invoice') {
				if (!additionalValue.company.isValid) {
					tempErrorArray.push('Podaj nazwę firmy');
				}
				if (!additionalValue.address.isValid) {
					tempErrorArray.push('Podaj adres prowadzenia działalności');
				}
				if (!additionalValue.nip.isValid) {
					tempErrorArray.push('Podaj prawidłowy numer NIP');
				}
				if (!additionalValue.regon.isValid) {
					tempErrorArray.push('Podaj prawidłowy numer REGON');
				}
				if (!additionalValue.account.isValid) {
					tempErrorArray.push(
						'Nieprawidłowy numer rachunku bankowego',
					);
				}
			}
			
			if (payFormat === 'contract') {
				const {
					mainInfo: {inputs: mainInfoInputs},
					secInfo,
				} = additionalValue;
				if (!mainInfoInputs.birthDate.isValid) {
					tempErrorArray.push('Data urodzenia');
				}
				if (!mainInfoInputs.birthPlace.isValid) {
					tempErrorArray.push('Miejsce urodzenia');
				}
				if (!mainInfoInputs.fatherName.isValid) {
					tempErrorArray.push('Imię ojca');
				}
				if (!mainInfoInputs.motherName.isValid) {
					tempErrorArray.push('Imię matki');
				}
				if (!mainInfoInputs.citizenship.isValid) {
					tempErrorArray.push('Obywatelstwo');
				}
				if (!mainInfoInputs.familyName.isValid) {
					tempErrorArray.push('Nazwisko rodowe');
				}
				if (!mainInfoInputs.pesel.isValid) {
					tempErrorArray.push('Numer PESEL');
				}
				if (!mainInfoInputs.taxOffice.isValid) {
					tempErrorArray.push('Adres urzędu skarbowego');
				}
				if (!mainInfoInputs.account.isValid) {
					tempErrorArray.push('Numer konta bankowego');
				}
				if (!mainInfoInputs.city.isValid) {
					tempErrorArray.push('Nazwa miejscowości');
				}
				if (!mainInfoInputs.street.isValid) {
					tempErrorArray.push('Adres lokalu');
				}
				if (!mainInfoInputs.houseNumber.isValid) {
					tempErrorArray.push('Numer lokalu');
				}
				if (!mainInfoInputs.apartment.isValid) {
					tempErrorArray.push('Numer mieszkania');
				}
				if (!mainInfoInputs.zipCode.isValid) {
					tempErrorArray.push('Kod pocztowy');
				}
				if (!mainInfoInputs.post.isValid) {
					tempErrorArray.push('Adres poczty');
				}
				if (!mainInfoInputs.voivodeship.isValid) {
					tempErrorArray.push('Nazwa województwa');
				}
				if (!mainInfoInputs.district.isValid) {
					tempErrorArray.push('Dzielnica');
				}
				if (!mainInfoInputs.community.isValid) {
					tempErrorArray.push('Gmina');
				}
				
				if (!secInfo.isEmployed) {
					tempErrorArray.push('Rodzaj zatrudnienia');
				} else {
					if (
						!additionalValue.employerInfo.inputs.employerName.isValid
					) {
						tempErrorArray.push('Nazwa pracodawcy');
					}
					if (
						!additionalValue.employerInfo.inputs.employerAddress
							.isValid
					) {
						tempErrorArray.push('Adres firmy pracodawcy');
					}
				}
				
				if (!secInfo.isTaxOfficeRegister) {
					tempErrorArray.push('Deklaracja formy opodatkowania');
				}
				if (!secInfo.isPayQuote) {
					tempErrorArray.push('Przychody');
				}
				if (!secInfo.isPensioner) {
					tempErrorArray.push('Status rencisty');
				}
				if (!secInfo.isCripple) {
					tempErrorArray.push('Status niepełnosprawności');
				} else {
					if (!secInfo.crippleLevel) {
						tempErrorArray.push('Poziom stopnia niepełnosprawności');
					}
				}
				if (!secInfo.isCareVacation) {
					tempErrorArray.push('Status urlopu wychowawczego');
				} else {
					if (
						!additionalValue.careVacationInfo.inputs.startVacation
							.isValid
					) {
						tempErrorArray.push('Podaj datę urlopu wychowawczego');
					}
					if (
						!additionalValue.careVacationInfo.inputs.endVacation
							.isValid
					) {
						tempErrorArray.push(
							'Podaj datę zakończenia urlopu wychowawczego',
						);
					}
				}
				if (!secInfo.isEntrepreneur) {
					tempErrorArray.push('Forma prowadzenia działalności');
				} else {
					if (!secInfo.taxLevel) {
						tempErrorArray.push(
							'Podaj rodzaj opodatkowania działalności',
						);
					}
				}
			}
			
			if (!isAcceptPP) {
				tempErrorArray.push('Zaakceptuj politykę prywatności');
			}
			
			setErrorArray([...tempErrorArray]);
			
			openValidationModal()
			
		}
	}
	const payFormatSetter = value => setPayFormat(value)
	const drugFormatSetter = value => setDrugFormat(value)
	
	return (
		<>
			<div className="registration">
				<div className="container">
					<DashTitle mainTitle="REJESTRACJA"/>
					<form className="registration__form" onSubmit={submitHandler}>
						<div className="registration__form-content unregThumbColor">
							<FormInput
								id="name"
								element="input"
								type="text"
								label="Imię"
								validators={[VALIDATOR_REQUIRE()]}
								errorText="sprawdź swoje imię"
								onInput={inputHandler}
							/>
							<FormInput
								id="surname"
								element="input"
								type="text"
								label="Nazwisko"
								validators={[VALIDATOR_REQUIRE()]}
								errorText="sprawdź swoje nazwisko"
								onInput={inputHandler}
							/>
							<FormInput
								id="userTitle"
								element="input"
								type="text"
								label="Afiliacja"
								validators={[VALIDATOR_REQUIRE()]}
								errorText="sprawdź swoją afiliację"
								onInput={inputHandler}
							/>
							<FormInput
								id="email"
								element="input"
								type="email"
								label="Adres e-mail"
								validators={[VALIDATOR_EMAIL()]}
								errorText="sprawdź swoj e-mail"
								onInput={inputHandler}
							/>
							<FormInput
								id="pwz"
								element="input"
								type="text"
								label="Numer PWZ"
								validators={[VALIDATOR_LENGTH(7), VALIDATOR_NUMBER()]}
								errorText="sprawdź swoj pwz"
								onInput={inputHandler}
							/>
							<FormInput
								id="telephone"
								element="input"
								type="text"
								label="Numer telefonu"
								placeholder="123456789"
								validators={[VALIDATOR_NUMBER(), VALIDATOR_LENGTH(9)]}
								errorText="sprawdź numer telefonu"
								onInput={inputHandler}
							/>
							<RegistrationSelect
								id="drugFormat"
								value={drugFormat}
								variantsArray={drugs}
								defaultValueTitle="Wybierz lek"
								onChangeHandler={drugFormatSetter}
							/>
							<FormInput
								id="password"
								element="input"
								type="password"
								label="Hasło"
								validators={[VALIDATOR_PASSWORD()]}
								errorText="sprawdź swoje hasło"
								onInput={inputHandler}
							/>
							<FormInput
								id="confirmPassword"
								element="input"
								type="password"
								label="Powtórz hasło"
								validators={[VALIDATOR_PASSWORD()]}
								errorText="sprawdź swoje hasło"
								onInput={inputHandler}
							/>
							<RegistrationSelect
								id="payFormat"
								value={payFormat}
								variantsArray={payFormatData}
								defaultValueTitle="Wybierz formę rozliczania"
								onChangeHandler={payFormatSetter}
							/>
							{hiddenArea}
							<label
								className={`registration__acceptPPLabel ${isAcceptPP && 'registration__acceptPPLabel-checked'}`}
								htmlFor="acceptCheckBox">
								Przeczytałem i akceptuję <a
								href="../../../assets/pdf/SERWIS_HCP_Portal_Polityka_Prywatności_(01.04.2020)%5B32%5D.pdf"
								download="Politykę prywatności.pdf">Politykę prywatności</a>
								<input
									type="checkbox"
									id="acceptCheckBox"
									checked={isAcceptPP}
									onChange={() => setIsAcceptPP(prev => !prev)}
								/>
							</label>
						</div>
						<div className="registration__buttonArea">
							<Button to="/">Powrót</Button>
							<Button type="submit">Zarejestruj się</Button>
						</div>
					</form>
				</div>
			</div>
			{
				isValidationModal &&
				<ValidationModal
					errorArray={errorArray}
					closeModal={closeValidationModal}
					clearArray={clearErrorArray}
				/>
			}
		</>
	)
}

export default Registration;