import React, {useContext} from 'react';
import {Circles} from 'react-loader-spinner';
import {AppContext} from '../../context/app/appContext';

const LocalLoader = () => {
	const {appState: {userInfo: {drug : {mainColor}}}} = useContext(AppContext);
	
	return (
		<div className="loader">
			<Circles
				color={mainColor ? mainColor : 'rgba(101, 184, 197, .8)'}
				width={60}
				height={60}
			/>
		</div>
	)
}

export default LocalLoader;