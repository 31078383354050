import React, {useEffect, useRef, useState} from 'react';
import Button from './Button';

const ImageUploads = (props) => {
	const [file, setFile] = useState();
	const [previewUrl, setPreviewUrl] = useState();
	const [isValid, setIsValid] = useState(true);
	
	const filePickerRef = useRef();
	
	useEffect(() => {
		if (!file) {
			return
		}
		const fileReader = new FileReader();
		fileReader.onload = () => {
			setPreviewUrl(fileReader.result)
		}
		fileReader.readAsDataURL(file);
	}, [file])
	
	const pickedHandler = (event) => {
		let pickedFile;
		let fileIsValid = isValid;
		if (event.target.files && event.target.files.length === 1) {
			pickedFile = event.target.files[0];
			setFile(pickedFile);
			setIsValid(true);
			fileIsValid = true;
		} else {
			setIsValid(false);
			fileIsValid = false;
		}
		
		props.onInput(props.id, pickedFile, fileIsValid)
	}
	
	const pickImageHandler = () => filePickerRef.current.click();
	
	return (
		<div className='image__upload'>
			<input
				id={props.id}
				ref={filePickerRef}
				style={{display: 'none'}}
				type="file"
				accept=".jpg, .png, .jpeg, .pdf, .doc, .docx, .odt, .ods, .odp"
				onChange={pickedHandler}
			/>
			<div className={`image__upload-wrapper ${props.center && 'center'}`}>
				<div className="image__upload-preview">
					{previewUrl && <img src={previewUrl} alt="Preview"/>}
				</div>
				<Button type="button" onClick={pickImageHandler}>
					{!props.thumb && !props.arrow ? 'Wybierz plik' : ''}
					{props.thumb ? 'choose thumb img' : ''}
					{props.arrow ? 'choose arrow img' : ''}
				</Button>
			</div>
			{!isValid && <p>{props.errorText}</p>}
		</div>
	)
}

export default ImageUploads;