import React, {useContext} from 'react';
import {AppContext} from '../../context/app/appContext';

const DynamicStyles = () => {
	const {appState: {userInfo: {drug}}} = useContext(AppContext)
	
	const defaultMainColor = 'rgba(101, 184, 197, .8)'
	const defaultSecondColor = '#4A4A4A';
	const defaultArrow = '/assets/arrow.png';
	const defaultScrollThumb = '/assets/scrollThumb.png';
	
	const mainColor = drug.mainColor ? drug.mainColor : defaultMainColor;
	const secondColor = drug.secondColor ? drug.secondColor : defaultSecondColor;
	const subMenuArrow = drug.arrow ? drug.arrow : defaultArrow;
	const scrollThumb = drug.scrollThumb ? drug.scrollThumb : defaultScrollThumb;
	
	
	return <div dangerouslySetInnerHTML={{
		__html:
			`<style>
				.header__menu-link:hover,
				.header__menu-activeLink {
					color: ${mainColor}
				}
				.newCaseMenu,
				.caseCard__titleLine,
				.rangeInput__input::-webkit-slider-thumb {
					background-color: ${mainColor};
				}
				.newCaseMenu__link:after,
				.newCaseMenu__fLink:after,
				.newCaseMenu__activeLink:after {
					background-color: ${secondColor};
				}
				.caseCard {
					border-color: ${mainColor};
				}
				.newCaseMenu > .container > nav {
					border-color: ${secondColor};
				}
				.newCaseMenu__link, .newCaseMenu > .container > nav {
					color: ${secondColor};
				}
				.header__subMenu-menuArrow, .showModalButton:after {
					background: url(${subMenuArrow}) no-repeat;
				}
				.customSelect > select {
					background: url(${subMenuArrow}) no-repeat;
					background-position-x: 98%;
					background-position-y: 5px;
				}
				.quillWrapper > .quill > .ql-container > .ql-editor::-webkit-scrollbar-thumb,
				.doctorDash__cardsArea::-webkit-scrollbar-thumb,
				.registration__form-content::-webkit-scrollbar-thumb,
				.myCases__content::-webkit-scrollbar-thumb,
				.supervisorDash__cardsArea::-webkit-scrollbar-thumb,
				.customTable > tbody::-webkit-scrollbar-thumb,
				.additionalTable > tbody::-webkit-scrollbar-thumb {
					background: transparent url(${scrollThumb}) no-repeat center center;
				}
			</style>`,
	}}/>
}
export default DynamicStyles;