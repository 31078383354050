import React, {useContext} from 'react';
import {validDateStr} from '../../utils/helpfunc';
import {AppContext} from '../../context/app/appContext';

const CaseCard = ({caseData, drugName}) => {
	
	const {getCasePDF} = useContext(AppContext);
	
	const onClickHandler = () => {
		getCasePDF(caseData.id);
	}
	
	return (
		<div className="caseCard" onClick={onClickHandler}>
			<div className="caseCard__title">
				<p>{validDateStr(new Date(caseData.date))}</p>
				<span/>
				<p>{drugName}</p>
			</div>
			<div className="caseCard__titleLine"/>
			<div className="caseCard__infoArea">
				<div className="caseCard__caseTitle">
					<h6>Tytuł:</h6>
					<p>{caseData.title}</p>
				</div>
				<div className="caseCard__caseAuthor">
					<h6>Autor:</h6>
					<p>{caseData.author}</p>
				</div>
			</div>
		</div>
	)
}


export default CaseCard;