import React from 'react';

const RadioGroup = ({name, headerTitle, footerTitle, valueHandler, radioValueArray}) => (
	<div className="radioGroup">
		<h6 className="radioGroup__title">{headerTitle}</h6>
		<div
			className="radioGroup__radioButtonArea"
			onChange={(event) => valueHandler(event.target.name, event.target.value)}
		>
			{radioValueArray.map(el => (
				<label key={el.value} htmlFor={`${name}-${el.value}`}>
					{el.description}
					<input
						id={`${name}-${el.value}`}
						type="radio"
						value={el.value}
						name={name}
					/>
				</label>
			))}
		</div>
		{footerTitle && <h6 className="radioGroup__title">{footerTitle}</h6>}
	</div>
)

export default RadioGroup;